import { DayType } from "../typings/ScheduleType";
import { Fragment, useEffect, useState } from 'react'
import classNames from "../util/classNames";
import { useAttendeeStore } from "../stores/attendeeStore";
import AttendeeDetailDialog from './AttendeeDetailDialog';
import { useSponsorStore } from "../stores/sponsorStore";
import SponsorDetailDialog from "./SponsorDetailDialog";
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid';
import { marked } from 'marked';
import parse from 'html-react-parser'
import dayjs from "dayjs";
import clsx from "clsx";
type Props ={
  fullDay:DayType,
  filterValue: string,
  setFilterValue: (value: string) => void
}
export default function ShowSchedule({fullDay, filterValue, setFilterValue}: Props) {
  const groupedSessions = fullDay.sessions.reduce((acc, session) => {
    const key = `${session.start.seconds}-${session.end.seconds}-${session.type}`;
    if (session.type === "Longevity Experiences") {
      if (!acc[key]) {
        acc[key] = {
          ...session,
          groupedSessions: []
        };
      }
      acc[key].groupedSessions.push(session);
      if (acc[key].groupedSessions.length > 1) {
        acc[key].grouped = true;
      }
    } else {
      acc[key] = session;
    }
    return acc;
  }, {} as { [key: string]: any });

  const sessionList = Object.values(groupedSessions);

  return (
    <div className="flex flex-col w-full max-w-2xl mx-auto space-y-3">
      <h1 className="mx-auto text-2xl font-bold"></h1>
        <div key={fullDay.date} className="flex flex-col space-y-5">
          <div className="w-full py-2 md:py-5 text-xl uppercase font-bold text-center text-gold">
            {fullDay.date}
          </div>
          <div className="flex flex-row items-center justify-center space-x-3 pb-6">
            <button onClick={() => { setFilterValue("All")}} className={clsx("uppercase font-semibold px-3 py-2 hover:opacity-75 active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default text-white", filterValue == "All" ? "bg-indigo-500" : "bg-halfDarkGold"  )}>
              All Programming
            </button>
            <button onClick={() => { setFilterValue("Speaker Session")}} className={clsx("uppercase font-semibold px-3 py-2 hover:opacity-75 active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default text-white", filterValue == "Speaker Session" ? "bg-a360red" : "bg-halfDarkGold"  )}>
              Stage Programming Only
            </button>
          </div>
          <div className="flex flex-col space-y-5">
            {!sessionList.length ?
              <div className="text-center font-semibold text-lg">Sessions Loading...</div>
            : sessionList.filter(e => filterValue == "Speaker Session" ? e.type == "Speaker Session" : true).map((event, index) => {
              const isSameTime =
                index > 0 && sessionList[index - 1].start === event.start;
              return (
                <div key={"event"+index} className={classNames(true ? "bg-darkGold" : "bg-slate-100", "rounded")}>
                  <div className="grid grid-cols-3 p-3" key={index}>
                    <div className={`col-span-1 px-1 text-left pt-2 ${isSameTime && "opacity-100"}`}>
                      {event.startDayJS!.tz('America/Los_Angeles').format('h:mma - ') + event.endDayJS!.tz('America/Los_Angeles').format('h:mma')}
                    </div>
                    <div className="flex flex-col col-span-2 space-y-2">
                      {event.grouped && (
                        <div className="text-left">
                          <div className="font-semibold text-lg break-words text-brightGold uppercase">
                            Longevity Experiences
                          </div>
                          {/* <div className="w-[60px] h-[3px] bg-brightGold rounded-full mt-2"></div> */}
                        </div>
                      )}
                      {event.groupedSessions ? event.groupedSessions.map((groupedEvent: any, groupedIndex: number) => (
                        <div key={"groupedEvent"+groupedIndex} className="text-left">
                          <div className="font-semibold text-lg break-words">
                            {groupedEvent.title}
                          </div>
                          {groupedEvent.location && groupedEvent.location.length > 0 && (
                            <div className="flex flex-col">
                                <span className="italic text-gray-300">
                                  {groupedEvent.location} {groupedEvent.room && ("(" +groupedEvent.room.trim()+ ")")}
                                </span>
                            </div>
                          )}
                          {(groupedEvent.description || true) && (
                            <>
                              <div className="flex flex-col mb-2">
                                {/* <span>{parse(groupedEvent.description)}</span> */}
                              </div>
                              <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                {
                                  groupedEvent.speakers?.map((s:string) => <SpeakerViewButton key={s} id={s} />)
                                }
                              </div>
                            </>
                          )}
                          {groupedEvent.longevity_experience && (
                              <>
                                <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                  <LongevityExperienceViewButton key={groupedEvent.longevity_experience} id={groupedEvent.longevity_experience} />
                                </div>
                              </>
                          )}
                          {groupedEvent.additional_info && (
                              <>
                                <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                  <MoreInfoViewButton key={groupedEvent.additional_info} id={groupedEvent.additional_info} additionalInfo={groupedEvent.additional_info} />
                                </div>
                              </>
                          )}
                          {groupedEvent.faculty_slides && dayjs().isAfter(groupedEvent.endDayJS) && (
                            <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                <button onClick={() => { window.open(groupedEvent.faculty_slides, '_blank') }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-cyan text-white">
                                  View Slides
                                </button>
                            </div>
                          )}
                          {groupedIndex < event.groupedSessions.length - 1 && (
                            <div className="h-[1px] bg-white mt-4 w-[60px]"></div>
                          )}
                        </div>
                      )) : (
                        <>
                          {event.title && (
                            <div className="text-left">
                              <div className="font-semibold text-lg break-words">
                                {event.title}
                              </div>
                              {event.location && event.location.length > 0 && (
                                <div className="flex flex-col">
                                    <span className="italic text-gray-300">
                                      {event.location} {event.room && ("(" +event.room.trim()+ ")")}
                                    </span>
                                </div>
                              )}
                              {(event.description || true) && (
                                <>
                                  <div className="flex flex-col mb-2">
                                    {/* <span>{parse(event.description)}</span> */}
                                  </div>
                                  <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                    {
                                      event.speakers?.map((s:string) => <SpeakerViewButton key={s} id={s} />)
                                    }
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                          {event.longevity_experience && (
                              <>
                                <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                  <LongevityExperienceViewButton key={event.longevity_experience} id={event.longevity_experience} />
                                </div>
                              </>
                          )}
                          {event.additional_info && (
                              <>
                                <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                  <MoreInfoViewButton key={event.additional_info} id={event.additional_info} additionalInfo={event.additional_info} />
                                </div>
                              </>
                          )}
                          {event.faculty_slides && dayjs().isAfter(event.endDayJS) && (
                            <div className="flex flex-col md:flex-row flex-wrap gap-3">
                                <button onClick={() => { window.open(event.faculty_slides, '_blank') }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-cyan text-white">
                                  View Slides
                                </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
              </div>
              );
            })}
          </div>
        </div>

    </div>
  );
}

//#region helper components
const SpeakerViewButton = ({id}: { id: string }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { attendees } = useAttendeeStore();

  const attendee = attendees.find(s => s.id === id);
  if(attendee){
    const name = attendee!.name
    return (
      <>
        <button onClick={() => { setOpenDialog(!openDialog) }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-gold text-black">
          {name}
        </button>
        {openDialog && <AttendeeDetailDialog id={id} closeModal={() => { setOpenDialog(false) }} />}
      </>
    )

  }
  else return <></>
}

const LongevityExperienceViewButton = ({id}: { id: string }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { sponsors } = useSponsorStore();

  const sponsor = sponsors.find(s => s.id === id);
  if(sponsor){
    const name = sponsor!.name
    return (
      <>
        <button onClick={() => { setOpenDialog(!openDialog) }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-teal text-white">
          Book / More Info
        </button>
        {openDialog && <SponsorDetailDialog id={sponsor.id} closeModal={() => { setOpenDialog(false) }} />}
      </>
    )

  }
  else return <></>
}

const MoreInfoViewButton = ({id, additionalInfo}: { id: string, additionalInfo: string }) => {
  const [openDialog, setOpenDialog] = useState(false);

    return (
      <>
        <button onClick={() => { setOpenDialog(!openDialog) }} className="px-3 py-2 hover:opacity-75 font-semibold active:opacity-100 rounded-md cursor-pointer disabled:opacity-30 disabled:cursor-default bg-teal text-white">
          More Info
        </button>
        {openDialog && (
          <Transition appear show={true} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={() => setOpenDialog(false)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/50" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <XMarkIcon onClick={() => setOpenDialog(false)} className="w-8 h-8 text-darkGold absolute right-2 top-2 cursor-pointer opacity-50 hover:opacity-75 active:opacity-100" />
                    <div className="text-black p-4 text-lg">
                      {additionalInfo ? parse(marked(additionalInfo).toString()) : "No additional information available."}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
          </Transition>
        )}
      </>
    )
}
//#endregion

import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import { DayType } from "./typings/ScheduleType";
import ShowSchedule from "./components/ShowSchedule";
import { useScheduleStore } from "./stores/scheduleStore";
import dayjs from "dayjs"; // Make sure to import dayjs
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(timezone);
dayjs.extend(utc);

export default function Schedule() {

  const days =  process.env.REACT_APP_MONTH_QUERY === "October 2024" ?
  [
    {
      day: "Tue",
      date: "Tuesday, October 08, 2024",
      dateString:"2024-10-08",
      sessions: []
    },
    {
      day: "Wed",
      date: "Wednesday, October 09, 2024",
      dateString:"2024-10-09",
      sessions: []
    },
    {
      day: "Thu",
      date: "Thursday, October 10, 2024",
      dateString:"2024-10-10",
      sessions: []
    },
    {
      day: "Fri",
      date: "Friday, October 11, 2024",
      dateString:"2024-10-11",
      sessions: []
    },
    {
      day: "Sat",
      date: "Saturday, October 12, 2024",
      dateString:"2024-10-12",
      sessions: []
    },
    {
      day: "Sun",
      date: "Sunday, October 13, 2024",
      dateString:"2024-10-13",
      sessions: []
    },
  ]
   : [
    {
      day: "Tue",
      date: "Tuesday, September 10, 2024",
      dateString:"2024-09-10",
      sessions: []
    },
    {
      day: "Wed",
      date: "Wednesday, September 11, 2024",
      dateString:"2024-09-11",
      sessions: []
    },
    {
      day: "Thu",
      date: "Thursday, September 12, 2024",
      dateString:"2024-09-12",
      sessions: []
    },
    {
      day: "Fri",
      date: "Friday, September 13, 2024",
      dateString:"2024-09-13",
      sessions: []
    },
    {
      day: "Sat",
      date: "Saturday, September 14, 2024",
      dateString:"2024-09-14",
      sessions: []
    },
    {
      day: "Sun",
      date: "Sunday, September 15, 2024",
      dateString:"2024-09-15",
      sessions: []
    },
  ];

  const { sessions } = useScheduleStore();
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [filterValue, setFilterValue] = useState<string>("All");
  const [sortedSchedule, setSortedSchedule] = useState<DayType[]>([])
  const [selected, setSelected] = useState(() => {
    const today = dayjs().tz('America/Los_Angeles').format('YYYY-MM-DD');
    const defaultIndex = days.findIndex(day => day.dateString === today);
    return defaultIndex >= 0 ? defaultIndex : 0;
  });
  const refScheduleContainer = useRef<HTMLDivElement | null>(null);
  window.scrollTo(0, 0);

  useEffect(() => {
    let allDays: DayType[] = JSON.parse(JSON.stringify(days))
    sessions.map((session) => {
      let index = allDays.findIndex((day) => {
        return day.dateString == session.startDayJS!.tz('America/Los_Angeles').format('YYYY-MM-DD')
      })
      if (index >= 0) {
        allDays[index].sessions.push(session)
      }
    })

    allDays.forEach((day) => {
      day.sessions.sort((a, b) => {
        return a.startDayJS! > b.startDayJS! ? 1 : -1
      })
    })
    setSortedSchedule((current) => [...allDays])
  }, [sessions])

  useEffect(() => {
    setIsLoading(false)
  }, [sortedSchedule]);

  return (
    <div className="flex flex-col space-y-3">
      <div className="flex flex-col">
        <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack border-b border-gray-500">
          SCHEDULE
        </div>
        <div className="flex">
          {sortedSchedule.length > 1 && sortedSchedule.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                setSelected(index);
                refScheduleContainer.current?.scrollTo({ top: 0 });
              }}
              className={`flex-1 px-3 py-3 border-x border-gray-500 text-center font-bold cursor-pointer border-b ${index !== selected
                ? "bg-darkGold text-white"
                : "bg-gold text-gray-800"
                }`}
            >
              {item.day}
            </div>
          ))}
        </div>
      </div>
      <div className="p-2 pb-10 bg-platBlack min-h-dvh">
        {!isLoading &&
          <ShowSchedule key={sortedSchedule[selected].day} fullDay={sortedSchedule[selected]} filterValue={filterValue} setFilterValue={setFilterValue} />
        }
      </div>
    </div>
  );
}

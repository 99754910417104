import React, { useEffect, useState } from 'react';
import { useSponsorStore } from './stores/sponsorStore';
import { SponsorType } from './typings/SponsorType';

const Partners: React.FC = () => {
  const { sponsors } = useSponsorStore();
  const [sortedSponsors, setSortedSponsors] = useState<SponsorType[]>([]);

  useEffect(() => {
    let spon = sponsors.filter((sponsor) => sponsor.status === "Confirmed" && !sponsor.image!.includes("plat_square"));
    // let parkHyattIncluded = false;
    spon = spon.filter((sponsor) => {
      if (sponsor.name!.startsWith("Park Hyatt")) {
          return false;
      }
      return true;
    });
    setSortedSponsors(spon.sort((a, b) => a.name!.localeCompare(b.name!)));
  }, [sponsors])

  return (
    <div className="flex flex-col items-center space-y-3 pb-6 min-h-dvh">
      <div className="py-2 pb-6 text-xl lg:text-2xl text-white font-bold text-center bg-platBlack">
        PARTNERS
      </div>
      <div className="text-xl">Thank you to our Partners.</div>
      <div className="grid grid-cols-1 md:grid-cols-3 justify-center p-4">
        {sortedSponsors.map((sponsor) => (
          <div key={sponsor.id} className="items-center p-4 rounded-lg shadow-lg w-full max-w-4xl">
            <img
              src={sponsor.image}
              alt={sponsor.name}
              className="w-72 h-48 object-contain bg-white p-4 mb-4"
            />

          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;